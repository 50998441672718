import { isEmpty } from "./isEmpty"

type Apps = {
  code: string;
  permission: boolean;
}

/**
 * 権限取得
 */
export const getPermission = (code: string, apps: Apps[]) => {
	if(isEmpty(code)) return false;

	let app = apps.find((v) => v.code === code);
	if(isEmpty(app)) {
		return true;
	} else {
		return app!.permission;
	}
}